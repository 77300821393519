import { createRouter, createWebHistory } from 'vue-router'

import NotFound from '@/views/NotFound.vue'
import AppointmentPortal from '@/views/AppointmentPortal.vue'
import BookAppointment from '@/views/BookAppointment.vue'
import ValuationImageUpload from '@/views/ValuationImageUpload.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'Not Found'
  },
  {
    path: '/book-appointment/:agreementId',
    component: BookAppointment,
    name: 'Appointment'
  },
  {
    path: '/appointment/:aid',
    component: AppointmentPortal,
    name: 'Book Appointment'
  },
  {
    path: '/valuation/:vid/image-upload',
    component: ValuationImageUpload,
    name: 'Valuation Image Upload'
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = `${to.name}`
  return next()
})

export default router
