<template>
  <div
    v-tippy="{ content: tooltip }"
    class="avatar select-none"
    :class="[
      'avatar-' + color,
      rounding,
      { 'avatar-no-border': noBorder },
      { 'w-6 h-6': size == 'xxs' },
      { 'w-8 h-8': size == 'xs' },
      { 'w-10 h-10': size == 'sm' },
      { 'w-12 h-12': size == 'md' },
      { 'w-14 h-14': size == 'lg' },
      { 'w-16 h-16': size == 'xl' },
      { 'w-20 h-20': size == '2xl' },
      { 'avatar-add-btn': addButton }
    ]"
  >
    <img
      v-if="validImgUrl && !imgHasError"
      :src="url"
      :class="[{ 'p-1.5 dark:brightness-0 dark:invert my-auto': isLogo }, !isLogo && !icon ? rounding : '']"
      @error="imgError(url)"
    />
    <!-- show image if url is not null, image does not have error -->
    <div
      v-else-if="text"
      class="font-semibold flex items-center justify-center h-full w-full"
      :class="[
        rounding,
        { 'text-[8px]': size == 'xxs' },
        { 'text-[10px]': size == 'xs' },
        { 'text-xs': size == 'sm' },
        { 'text-md': size == 'md' },
        { 'text-md': size == 'lg' },
        { 'text-lg': size == 'xl' }
      ]"
    >
      {{ text }}
    </div>
    <Icon
      v-else-if="icon"
      :type="icon"
      class="avatar-icon flex items-center justify-center h-full w-full"
      fa-style="fas"
      :class="[
        rounding,
        { 'text-[8px]': size == 'xxs' },
        { 'text-[10px]': size == 'xs' },
        { 'text-sm': size == 'sm' },
        { 'text-md': size == 'md' },
        { 'text-lg': size == 'lg' },
        { 'text-xl': size == 'xl' }
      ]"
    >
    </Icon>
    <div
      v-else-if="addButton"
      class="border-primary border-2 border-dashed font-semibold text-quaternary flex items-center justify-center h-full w-full"
      :class="[
        rounding,
        { 'text-[10px]': size == 'xs' },
        { 'text-xs': size == 'sm' },
        { 'text-md': size == 'md' },
        { 'text-md': size == 'lg' },
        { 'text-lg': size == 'xl' }
      ]"
    >
      <Icon type="plus" fa-style="fas" />
    </div>
    <!-- <Badge rounded :content="badgeContent">
      <div
        v-if="userPresenceUserId"
        v-tippy="{ content: userPresence.text }"
        class="avatar-user-presence"
        :class="[
          userPresence.color,
          { 'text-[10px]': size == 'xs' },
          { 'text-sm': size == 'sm' },
          { 'text-md': size == 'md' },
          { 'text-lg': size == 'lg' },
          { 'text-xl': size == 'xl' }
        ]"
      >
        <Icon :type="userPresence.icon" fa-style="fas" />
      </div>
    </Badge> -->
    <div
      v-if="statusIndicatorIcon"
      class="status-indicator"
      :class="[
        statusIndicatorColorClass,
        { 'status-indicator__xs': size == 'xs' },
        { 'status-indicator__sm': size == 'sm' },
        { 'status-indicator__md': size == 'md' },
        { 'status-indicator__lg': size == 'lg' },
        { 'status-indicator__xl': size == 'xl' },
        { 'status-indicator__2xl': size == '2xl' }
      ]"
    >
      <Icon :type="statusIndicatorIcon" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'

import { ref, watch, computed } from 'vue'

export default {
  components: {
    Icon
  },
  props: {
    size: {
      type: String,
      default: 'md'
    },
    url: {
      type: String,
      default: null
    },
    badgeContent: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'light'
    },
    rounding: {
      type: String,
      default: 'rounded-full'
    },
    statusIndicatorIcon: {
      type: String,
      default: null
    },
    statusIndicatorColorClass: {
      type: String,
      default: null
    },
    isLogo: {
      type: Boolean,
      default: false
    },
    addButton: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const validImgUrl = ref(false)
    const imgHasError = ref(false)

    function validateImgUrl(imgUrl) {
      let validImgTypes = ['.jpg', '.jpeg', '.png', '.svg']

      if (imgUrl != null && validImgTypes.find(type => imgUrl.includes(type))) {
        validImgUrl.value = true
      } else {
        validImgUrl.value = false
      }
    }

    function imgError(imgUrl) {
      imgHasError.value = true
      console.error('Failed to load image with url: ' + imgUrl)
    }

    validateImgUrl(props.url)
    // Check img url when props change (Mostly for dropdowns where the avatar image gets updated)
    watch(props, () => {
      validImgUrl.value = false
      imgHasError.value = false
      validateImgUrl(props.url)
    })

    return { validImgUrl, imgError, imgHasError }
  }
}
</script>

<style>
.avatar {
  position: relative;
  flex-shrink: 0;
  display: flex;
  background-color: var(--bg-tertiary);
  color: var(--text-secondary);
}
.avatar:not(.avatar-add-btn):not(.avatar-no-border) {
  border: 1.5px solid var(--border-secondary);
}
.avatar:hover {
  z-index: 1;
}

.avatar-icon {
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
}

.avatar.avatar-add-btn {
  background-color: transparent;
}
.avatar.avatar-add-btn:hover {
  background-color: var(--utility-gray-50);
  cursor: pointer;
}

.avatar-brand {
  background-color: var(--utility-brand-50);
  color: var(--text-brand);
}

.avatar-success {
  background-color: var(--utility-success-50);
  color: var(--text-success);
}

.avatar-warning {
  background-color: var(--utility-warning-50);
  color: var(--text-warning);
}

.avatar-danger {
  background-color: var(--utility-error-50);
  color: var(--text-error);
}

.avatar-info {
  background-color: var(--utility-purple-50);
  color: var(--text-info);
}

.status-indicator {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 2px solid var(--bg-primary);
  color: white;
}
.status-indicator__xs {
  height: 10px;
  width: 10px;
  font-size: 0.4rem;
  outline-width: 1.5px;
}
.status-indicator__sm {
  height: 12px;
  width: 12px;
  font-size: 0.45rem;
  outline-width: 2px;
}
.status-indicator__md {
  height: 12px;
  width: 12px;
  font-size: 0.45rem;
}
.status-indicator__lg {
  height: 14px;
  width: 14px;
  font-size: 0.45rem;
}
.status-indicator__xl {
  height: 16px;
  width: 16px;
  font-size: 0.6rem;
}
.status-indicator__2xl {
  height: 18px;
  width: 18px;
  font-size: 0.6rem;
}

.avatar-presence-status-offline {
  background-color: var(--bg-tertiary);
}
.avatar-presence-status-online {
  background-color: var(--success);
}
.avatar-presence-status-away {
  background-color: var(--warning);
}
</style>
