<template>
  <div class="p-2 max-w-[800px] m-auto">
    <FileUpload
      :header-title="null"
      :api-endpoint="uploadImagesApiEndpoint"
      :api-headers="{
        'X-Api-Access-Token':
          'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
      }"
      :api-post-data="{ uploadedBy: null, uploadedByType: 'customer' }"
      button-position="full-width"
      :accepted-file-types="['image/jpeg', 'image/png']"
      @uploaded="$nextTick(() => fetchValuation(valuationId))"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

import FileUpload from '@/components/file-upload/FileUpload.vue'
const route = useRoute()

const valuationId = route.params.vid
const uploadImagesApiEndpoint = ref(`https://api.retainauto.com/v1/valuations/${valuationId}/upload-images`)

const state = ref(null)

// Fetch valuation data from API
async function fetchValuation(id) {
  return new Promise((resolve, reject) => {
    if (id) {
      try {
        axios
          .get(`https://api.retainauto.com/v1/valuations/${id}`, {
            headers: {
              'X-Api-Access-Token':
                'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
            }
          })
          .then(response => {
            if (response.data.response.status == 'success') {
              state.value = response.data.data
              resolve()
            } else {
              console.error(response.data.response.message)
              reject()
            }
          })
      } catch (error) {
        console.error(`The request failed: ${error.message}`)
        reject()
      }
    } else {
      console.error("Couldn't load content - invalid or missing valuation ID")
      reject()
    }
  })
}

onMounted(() => {
  fetchValuation(valuationId)
})
</script>
