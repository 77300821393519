<template>
  <!-- video method -->
  <div v-if="appStatus.status == 'current' || appStatus.status == 'future'">
    <div v-if="modelValue.method == 'video'" class="flex flex-col items-center gap-4">
      <div class="text-center">You will be able to join the video call 10 minutes before the appointment</div>
      <Button
        label="Join"
        icon="video"
        severity="secondary"
        :disabled="!videoLinkEnabled(modelValue.time)"
        :background-color="videoLinkEnabled(modelValue.time) ? modelValue.manufacturerColor : ''"
        class="sm:max-w-[340px] w-full"
      />
    </div>

    <!-- video external method -->
    <div v-if="modelValue.method == 'video_external'" class="flex flex-col items-center gap-2 sm:flex-row sm:items-start">
      <Icon type="video" faStyle="far" class="text-2xl sm:text-md pt-[4px]" />
      <div class="text-center sm:text-left text-xl sm:text-md">
        {{
          appStatus.status == 'future'
            ? 'We will send you a link to join the video call close to the appointment time'
            : 'We have sent you a link to join the video call'
        }}
      </div>
    </div>

    <!-- phone method -->
    <div v-if="modelValue.method == 'phone'" class="flex flex-col items-center gap-1 sm:flex-row sm:gap-1">
      <Icon type="phone" faStyle="far" class="text-2xl sm:text-md" />
      <span class="text-center sm:text-left sm:text-md text-xl">We will call you at the time of the appointment</span>
    </div>

    <!-- onsite method -->
    <div v-if="modelValue.method == 'physical'" class="flex flex-col sm:flex-row w-full items-center justify-between">
      <div class="flex justify-center">
        <Icon type="location-dot" faStyle="far" class="text-xl sm:text-md pt-[4px] mr-[4px] sm:w-[15px] w-[22px]" />
        <div class="font-medium text-xl sm:text-md">{{ modelValue.dealershipName }}</div>
      </div>
      <div class="items-end justify-center sm:w-auto mt-4 sm:mt-0">
        <a :href="directionsLink" target="_blank">
          <Button
            label="Directions"
            icon="location-arrow"
            class="w-full text-md"
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            :style="{ backgroundColor: modelValue.manufacturerColor }"
          />
        </a>
      </div>
    </div>
  </div>
  <div v-if="appStatus.status == 'past' || modelValue.isCancelled">
    <div v-if="modelValue.method == 'phone'">
      <div class="flex items-center gap-1 justify-center sm:justify-normal">
        <Icon type="phone" faStyle="far" class="text-xl sm:text-md" />
        <div class="text-2xl sm:text-md">Phone appointment</div>
      </div>
    </div>
    <div v-if="modelValue.method == 'video' || modelValue.method == 'video_external'">
      <div class="flex items-center gap-1 justify-center sm:justify-normal">
        <Icon type="video" faStyle="far" class="text-2xl sm:text-md" />
        <div class="text-2xl sm:text-md">Video appointment</div>
      </div>
    </div>
    <div v-if="modelValue.method == 'physical'">
      <div class="font-bold text-center sm:text-left text-2xl sm:text-md">{{ modelValue.dealershipName }}</div>
      <div class="flex items-center gap-1 mt-1 justify-center sm:justify-normal">
        <Icon type="location-dot" faStyle="far " class="text-xl sm:text-md sm:w-[15px] w-[22px]" />
        <div class="text-xl sm:text-md">On site appointment</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

import Button from './button/Button.vue'
import Icon from './icon/Icon.vue'
import { useBreakPoints } from '@/composables/useWindowSize.js'

import dayjs from 'dayjs'

export default {
  name: 'AppointmentMethod',
  components: {
    Button,
    Icon
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    appStatus: {
      type: Object,
      default: () => {}
    }
  },

  setup(props) {
    const videoLinkEnabled = appDateTime => {
      if (dayjs(appDateTime).subtract(10, 'minute') < dayjs() && dayjs() < dayjs(appDateTime).add(1, 'hour')) {
        return true
      } else {
        return false
      }
    }

    const directionsLink = computed(() => {
      if (props.modelValue.dealershipName) {
        let encodedAddress = encodeURIComponent(props.modelValue.dealershipName)
        return `https://www.google.com/maps?q=${encodedAddress}`
      } else {
        return ''
      }
    })
    return { videoLinkEnabled, directionsLink, useBreakPoints }
  }
}
</script>

<style></style>
