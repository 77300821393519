<template>
  <div v-if="hasError" class="input-error">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    hasError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: 'Default Error Text'
    }
  }
}
</script>

<style>
.input-error {
  color: var(--text-error);
  font-size: var(--text-sm);
  margin-top: 2px;
}
</style>
